var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { noHeader: true } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo1,
                    editable: _vm.editable && !_vm.disabled,
                    label: "MSDS",
                  },
                  on: { files: (fileList) => _vm.files(fileList, "MSDS") },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo2,
                    editable: _vm.editable && !_vm.disabled,
                    label: "화학물질 성분내역서",
                  },
                  on: { files: (fileList) => _vm.files(fileList, "INGRDIENT") },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo3,
                    editable: _vm.editable && !_vm.disabled,
                    label: "화학물질 확인명세서",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo4,
                    editable: _vm.editable && !_vm.disabled,
                    label: "성적서",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo6,
                    editable: _vm.editable && !_vm.disabled,
                    label: "유해화학물질 신고/인허가",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo5,
                    editable: _vm.editable && !_vm.disabled,
                    label: "기타",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }